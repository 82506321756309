import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { REQUEST_FETCHING, REQUEST_SUCCESS } from 'app/constants/requestStatus';
import {
  products as allProducts,
  PRODUCT_BROADBAND,
} from 'app/redux/modules/Bundles/constants';
import { price as priceProp } from 'app/constants/propTypes';

import RequoteDialog from '../RequoteDialog';
import RequireOtherServiceDialogComponent from '../RequireOtherServiceDialog/RequireOtherServiceDialog';

import AddOns from './AddOns';
import OtherServices from './OtherServices';
import RemoveServiceDialog from './RemoveServiceDialog';
import TileSkeleton from './Shared/TileSkeleton';
import SelectedServices from './SelectedServices';
import {
  broadbandProps,
  energyProps,
  insuranceProps,
  mobileProps,
} from './SelectedServices/Services/propTypes';
import TotalPrice from './TotalPrice';
import TbybDialog from './TryBeforeYouBuyRemovalDialog';
import { FreeEnergyBanner } from 'modules/Shared/Banners/FreeEnergy';
import { LegalInformation } from './LegalInformation';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 750,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3, 0),
    margin: theme.spacing(4, 0),
    justifyContent: 'start',
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: theme.spacing(0, 2, 2, 2),
  },
}));

const OrderSummary = ({
  acceptEnergyQuotes,
  addOns,
  addService,
  breakdown,
  discountedTotalPrice,
  editService,
  getSummaryRequest,
  hasFFBroadband,
  isApplicationComplete,
  isApplicationReadOnly,
  isPartner,
  isSales,
  otherAddOns,
  otherServices,
  products,
  removeProductError,
  removeProductModal,
  removeProductRequest,
  requestStatus: { summary: summaryStatus },
  serviceCount,
  setRemoveProductError,
  setRemoveProductModal,
  setTbybModal,
  tbybModal,
  totalPrice,
  hasEvInterest,
  shouldShowFreeEnergyOffer,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getSummaryRequest(isApplicationComplete);
  }, []);

  const [legalBitExpanded, setLegalBitExpanded] = useState(false);

  const hasBbTbybPromo = breakdown?.services?.some(
    (service) =>
      service.name === 'broadband' &&
      service.promoMonthlyCost?.cost?.value === 0
  );

  const isApplicationEditable =
    !isApplicationReadOnly && !isApplicationComplete;

  return (
    <>
      {removeProductModal?.open &&
        removeProductModal.changes &&
        removeProductModal.energyQuotes && (
          <RequoteDialog
            serviceCount={serviceCount}
            open={removeProductModal.open}
            productToRemove={removeProductModal.productToRemove}
            changes={removeProductModal.changes}
            energyQuotes={removeProductModal.energyQuotes}
            hasEvInterest={hasEvInterest}
            onClose={() => setRemoveProductModal(false)}
            acceptEnergyQuote={(quoteIds) => acceptEnergyQuotes(quoteIds)}
            confirmRemoveProduct={() => {
              removeProductRequest(removeProductModal.productToRemove, false);
              setRemoveProductModal(false);
            }}
          />
        )}
      {removeProductModal?.open &&
        removeProductModal.changes &&
        !removeProductModal.energyQuotes && (
          <RequoteDialog
            open={removeProductModal.open}
            productToRemove={removeProductModal.productToRemove}
            changes={removeProductModal.changes}
            hasEvInterest={hasEvInterest}
            onClose={() => setRemoveProductModal(false)}
            confirmRemoveProduct={() => {
              removeProductRequest(removeProductModal.productToRemove, false);
              setRemoveProductModal(false);
            }}
          />
        )}
      {removeProductModal?.open && !removeProductModal.changes && (
        <RemoveServiceDialog
          open={removeProductModal.open}
          product={removeProductModal.productToRemove}
          onCancel={() => setRemoveProductModal(false)}
          removeProduct={() => {
            removeProductRequest(removeProductModal.productToRemove, false);
            setRemoveProductModal(false);
          }}
        />
      )}
      {removeProductError.message && (
        <RequireOtherServiceDialogComponent
          open={removeProductError.open}
          onClose={() => {
            setRemoveProductError(false);
          }}
          message={removeProductError.message}
        />
      )}
      <TbybDialog
        open={tbybModal.open}
        removeProduct={() => {
          removeProductRequest(tbybModal.productToRemove, false);
          setTbybModal(false);
        }}
        editBroadband={() => {
          setTbybModal(false);
          editService(PRODUCT_BROADBAND);
        }}
        onCancel={() => setTbybModal(false)}
        productToRemove={tbybModal.productToRemove}
        bbPrice={products?.broadband?.price}
        hasFFBroadband={hasFFBroadband}
      />
      <div className={classes.container}>
        {shouldShowFreeEnergyOffer && (
          <FreeEnergyBanner legalBitExpanded={legalBitExpanded} />
        )}
        <Typography variant="h3" color="primary">
          Your bundle
        </Typography>
        {summaryStatus === REQUEST_FETCHING &&
          allProducts.map((product) => <TileSkeleton key={product} />)}
        {summaryStatus === REQUEST_SUCCESS && (
          <>
            <SelectedServices
              {...products}
              removeService={removeProductRequest}
              editService={editService}
              isEditable={isApplicationEditable}
              isPartner={isPartner}
              isSales={isSales}
              hasBbTbybPromo={hasBbTbybPromo}
            />
            {isApplicationEditable && (
              <OtherServices
                otherServices={otherServices}
                addService={addService}
              />
            )}
            <AddOns
              addOns={addOns}
              otherAddOns={otherAddOns}
              addService={addService}
              removeService={removeProductRequest}
              editService={editService}
              isEditable={isApplicationEditable}
              isPartner={isPartner}
            />
          </>
        )}
      </div>
      {shouldShowFreeEnergyOffer && (
        <LegalInformation onToggleLegalBit={setLegalBitExpanded} />
      )}
      {breakdown && (
        <TotalPrice
          breakdown={breakdown}
          totalPrice={totalPrice}
          discountedTotalPrice={discountedTotalPrice}
          hasBbTbybPromo={hasBbTbybPromo}
        />
      )}
    </>
  );
};

OrderSummary.propTypes = {
  acceptEnergyQuotes: PropTypes.func.isRequired,
  addOns: PropTypes.object.isRequired,
  addService: PropTypes.func.isRequired,
  breakdown: PropTypes.shape({
    totalMonthlyCost: priceProp,
    promoMonthlyCost: priceProp,
    totalInitialCost: priceProp,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        totalInitialCost: priceProp,
      })
    ),
  }).isRequired,
  discountedTotalPrice: priceProp,
  editService: PropTypes.func.isRequired,
  getSummaryRequest: PropTypes.func.isRequired,
  hasFFBroadband: PropTypes.bool.isRequired,
  hasEvInterest: PropTypes.bool.isRequired,
  isApplicationComplete: PropTypes.bool.isRequired,
  isApplicationReadOnly: PropTypes.bool.isRequired,
  isPartner: PropTypes.bool,
  isSales: PropTypes.bool,
  otherAddOns: PropTypes.arrayOf(PropTypes.object).isRequired,
  otherServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.shape({
    energy: energyProps,
    broadband: broadbandProps,
    mobile: mobileProps,
    insurance: insuranceProps,
  }).isRequired,
  removeProductError: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    message: PropTypes.string,
  }),
  removeProductModal: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    message: PropTypes.string,
    energyQuotes: PropTypes.arrayOf(PropTypes.object),
    changes: PropTypes.shape({
      products: PropTypes.shape({
        to: PropTypes.object,
        from: PropTypes.object,
      }),
      benefits: PropTypes.object,
    }),
    productToRemove: PropTypes.string,
  }).isRequired,
  removeProductRequest: PropTypes.func.isRequired,
  requestStatus: PropTypes.shape({
    summary: PropTypes.string,
  }).isRequired,
  serviceCount: PropTypes.number.isRequired,
  setRemoveProductError: PropTypes.func.isRequired,
  setRemoveProductModal: PropTypes.func.isRequired,
  setTbybModal: PropTypes.func.isRequired,
  tbybModal: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    productToRemove: PropTypes.string,
  }).isRequired,
  totalPrice: priceProp.isRequired,
  shouldShowFreeEnergyOffer: PropTypes.bool.isRequired,
};

export default OrderSummary;
