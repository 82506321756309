import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@utilitywarehouse/partner-ui-icons';
import { FREE_ENERGY_CLUB_TERMS_URL } from 'app/config/externalLinks';

import { useStyles } from './styles';

const LegalInformation = ({ onToggleLegalBit }) => {
  const classes = useStyles();
  const [showLegalBit, setShowLegalBit] = useState(false);

  const handleToggleLegalBit = () => {
    const newState = !showLegalBit;
    setShowLegalBit(newState);
    onToggleLegalBit(newState);
  };

  return (
    <>
      <Button
        variant="link"
        onClick={handleToggleLegalBit}
        endIcon={showLegalBit ? <ChevronUpIcon /> : <ChevronDownIcon />}
        classes={{ root: classes.button, endIcon: classes.chevron }}
      >
        <span className={classes.buttonText}>Legal Bit</span>
      </Button>
      {showLegalBit && (
        <Typography
          variant="body2"
          component="div"
          classes={{ root: classes.legalBitText }}
        >
          <h4 className={classes.bold}>£100 free energy offer</h4>
          <p>
            *
            <Link
              underline="always"
              href={FREE_ENERGY_CLUB_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms{' '}
            </Link>
            apply
          </p>
        </Typography>
      )}
    </>
  );
};
LegalInformation.propTypes = {
  onToggleLegalBit: PropTypes.func.isRequired,
};

export { LegalInformation };
