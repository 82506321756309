import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  button: {
    padding: theme.spacing(0),
    alignSelf: 'flex-start',
  },
  buttonText: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  chevron: {
    color: theme.palette.secondary.main,
  },
  section: {
    padding: theme.spacing(0),
  },
  legalBitText: {
    marginTop: theme.spacing(2),
  },
}));

export { useStyles };
